import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Card } from 'react-bootstrap';
import { LimbusCharacter } from '../../../modules/lc/common/components/lc-character';
import { LimbusEgo } from '../../../modules/lc/common/components/lc-ego';

const LimbusRerollPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="lc">
      <ul className="breadcrumb">
        <li>
          <Link to="/limbus-company/">Limbus Company</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/limbus-company/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Beginner team ideas</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/limbuscompany/categories/category_teamb.png"
            alt="Team Building"
          />
        </div>
        <div className="page-details">
          <h1>Beginner team ideas</h1>
          <h2>
            Struggling with building a team in Limbus Company? Get some
            inspiration inside!
          </h2>
          <p>
            Last updated: <strong>13/05/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          The team will consist of cores and other recommendation to fill up the
          other slots or improves the team. if you do not have them you can
          pretty much substitute them with anything you have already upgraded or
          one that have some synergy with them.
        </p>
        <SectionHeader title="Beginner Team" />
        <p>
          If you just started the game you could use this team to go through the
          early game content until you have decent pool of IDs.
        </p>
        <h5>Core of the team</h5>
        <div className="employees-container-card-mode-limbus">
          <Card className="avatar-card">
            <LimbusCharacter
              slug="lcb-sinner-heathcliff"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
          <Card className="avatar-card">
            <LimbusCharacter
              slug="lcb-sinner-outis"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
          <Card className="avatar-card">
            <LimbusCharacter
              slug="lcb-sinner-hong-lu"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
        </div>
        <h5>Other recommendations</h5>
        <p>
          You can pretty much fill the rest of the slot with IDs you have
          already upgraded or rerolled but here are some recommendation on who
          can fill it.
        </p>
        <ul>
          <li>
            <strong>[Kurokumo Clan Wakashu] Hong Lu</strong> - Even though he
            will fall off later on it's still a good investment as he only need
            Uptie 2 to function
          </li>
          <li>
            <strong>[Lobotomy Corp Remnant] Faust</strong> - Just overall great
            clashes and damage
          </li>
          <li>
            <strong>[Seven Association South Section 6] Ryoshu</strong> - Same
            as [Lobotomy Corp Remnant] Faust
          </li>
          <li>
            <strong>[Shi Association South Section 5] Ishmael</strong> -
            Basically [Kurokumo Clan Wakashu] Hong Lu but only in low health.
            She is given for free anyway so might as well use her.
          </li>
        </ul>
        <SectionHeader title="Severe Depression Team" />
        <p>
          The key to this team is to use [Rime Shank] Rodion’s E.G.O corrosion
          and [Molar Boatworks] Ishmael to stack sinking then follow it up with
          [Efflorescent E.G.O::Spicebush] Yi Sang S3 to deal a ton of damage.
          Any Rodion can also work as long as you have Uptie 4 Rime Shank but
          preferably [Dieci Assoc. South Section 4] as she has some synergy and
          can fuel it.
        </p>
        <h5>Core of the team</h5>
        <div className="employees-container-card-mode-limbus">
          <Card className="avatar-card">
            <LimbusCharacter
              slug="effloresced-e-g-o-spicebush-yi-sang"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
          <Card className="avatar-card">
            <LimbusCharacter
              slug="totally-not-molar-boatworks-fixer-ishmael"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
          <Card className="avatar-card">
            <LimbusCharacter
              slug="dieci-south-section-4-rodion"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
          <Card className="avatar-card">
            <LimbusEgo
              slug="rime-shank-rodion"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
        </div>
        <h5>Other recommendations</h5>
        <ul>
          <li>
            <strong>[Wuthering Heights Chief Butler] Outis</strong>
          </li>
          <li>
            <strong>[Edgar Family Heir] Gregor</strong>
          </li>
          <li>
            <strong>[Dieci Association] Hong Lu</strong> - Can deal decent
            damage while [Efflorescent E.G.O::Spicebush] Yi Sang S3 is not up
            yet,
          </li>
          <li>
            <strong>[Wuthering Heights Butler] Faust</strong> - Butler Faust can
            help with sinking stacks.
          </li>
        </ul>
        <SectionHeader title="Blade Lineage Team" />
        <p>
          Stack poise and pretty much kill everything with high numbers and
          damage from [Blade Lineage Mentor] Meursault’s buffs. Functions pretty
          much the same as pride resonance team meaning you won’t be able to
          fuel most E.G.Os, Also try to use at least 3 pride skills every turn
          to activate [Blade Lineage Mentor] Meursault’s passive.
        </p>
        <h5>Core of the team</h5>
        <div className="employees-container-card-mode-limbus">
          <Card className="avatar-card">
            <LimbusCharacter
              slug="blade-lineage-mentor-meursault"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
          <Card className="avatar-card">
            <LimbusCharacter
              slug="blade-lineage-salsu-don-quixote"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
          <Card className="avatar-card">
            <LimbusCharacter
              slug="blade-lineage-salsu-yi-sang"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
          <Card className="avatar-card">
            <LimbusCharacter
              slug="blade-lineage-salsu-faust"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
        </div>
        <h5>Other recommendations</h5>
        <ul>
          <li>
            <strong>[The Pequod Captain] Ishmael</strong> - Blade Lineage team
            has a lot of pride meaning they can trigger pride resonance very
            consistently making her assist attack pretty much guaranteed which
            helps poise stacking massively and can even deal a lot of damage
            with [Blade Lineage Salsu] Faust - S1 for example,
          </li>
          <li>
            <strong>[Blade Lineage] Sinclair</strong> - With so much poise going
            around his passive on Uptie 4 can benefit a lot from it. other than
            that he has okay S2 and S1 and a massive nuke on S3,
          </li>
          <li>
            <strong>[Blade Lineage] Outis</strong> - Pretty much a must slot if
            you want to benefit from [Blade Lineage Mentor] Meursault’s 6 Blade
            Lineage passive although it is not recommended to slot her usually
            as she does not do much even with [Blade Lineage Mentor] Meursault.
          </li>
        </ul>
        <SectionHeader title="Prideful Team" />
        <p>
          The key to this team is to use [The Pequod Captain] Ishmael’s assist
          attack mechanic as often as possible with IDs that benefit greatly
          from using S1. The Absolute-Resonance team cannot fuel most E.G.O so
          instead they rely on having high value units with high clash power and
          damage. Any ID that has decent pride skill can synergize with them.
        </p>
        <h5>Core of the team</h5>
        <div className="employees-container-card-mode-limbus">
          <Card className="avatar-card">
            <LimbusCharacter
              slug="the-pequod-captain-ishmael"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
          <Card className="avatar-card">
            <LimbusCharacter
              slug="cinq-south-section-4-director-sinclair"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
          <Card className="avatar-card">
            <LimbusCharacter
              slug="w-corp-l3-cleanup-agent-ryoshu"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
          <Card className="avatar-card">
            <LimbusCharacter
              slug="blade-lineage-mentor-meursault"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
        </div>
        <h5>Other recommendations</h5>
        <ul>
          <li>
            <strong>[Blade Lineage Salsu] Faust</strong> - Great S1 for assist
            attack with pride and Blade Lineage synergy,
          </li>
          <li>
            <strong>[Edgar Family Chief Butler] Ryoshu</strong> - Assist attack
            helps her poise gain greatly with the capability of clearing mobs
            easily,
          </li>
          <li>
            <strong>[Twinhook Pirate Firstmate] Gregor</strong> - Can benefit
            from the bleed inflicted by others such as [Blade Lineage Salsu]
            Faust, [The Pequod Captain] Ishmael or “To Claim Their Bones” to
            gain stacks while also benefiting from assist attack,
          </li>
          <li>
            <strong>[Blade Lineage] Yi sang</strong> - Possess very high numbers
            and can snowball fights easily with [Blade Lineage Mentor]
            Meursault,
          </li>
          <li>
            <strong>[Lobotomy E.G.O::Regret] Faust</strong> - Same case as
            [Edgar Family Chief Butler] Ryoshu but with self tremor instead.
          </li>
        </ul>
        <SectionHeader title="Let Them Cook Team" />
        <p>
          The key to this team is to just stack burn while also having various
          damage sources such as burn itself, Dark flame from [Lobotomy
          E.G.O::Magic Bullet] Outis and the Liu Associations with [Dawn Office
          Fixer] Sinclair. Keep in mind that this team requires [LCB] Yi Sang’s
          support as it helps alleviate the sp drain massively meaning outside
          of md you would want an ID with gloom as [Dawn Office Fixer] Sinclair
          S1 is not enough. [Liu Association South Section 5] Hong Lu’s support
          passive is also good here since it helps maintaining count although
          not that necessary
        </p>
        <h5>Core of the team</h5>
        <div className="employees-container-card-mode-limbus">
          <Card className="avatar-card">
            <LimbusCharacter
              slug="lobotomy-e-g-o-magic-bullet-outis"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
          <Card className="avatar-card">
            <LimbusCharacter
              slug="dawn-office-fixer-sinclair"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
          <Card className="avatar-card">
            <LimbusCharacter
              slug="liu-assoc-south-section-4-director-rodion"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
          <Card className="avatar-card">
            <LimbusCharacter
              slug="liu-south-section-4-ishmael"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
        </div>
        <h5>Other recommendations</h5>
        <ul>
          <li>
            Any Meursault with sloth skills and Uptie 4 [Capote] E.G.O on
            corrosion inflicts a total of 57 Burn potency and count if
            conditions are fulfilled. [Liu Association South Section 6]
            Meursault can work but his skills are weak so it is not optimal to
            use him,
          </li>
          <li>
            <strong>[Liu Assoc. South Section 4] Ryoshu</strong> - Can hold [4th
            Match Flame] which is a really good ego for burn and the ID itself
            has decent synergy with burn,
          </li>
          <li>
            <strong>[The One Who Shall Grip] Sinclair</strong> -If you don’t
            have [Dawn Office Fixer] Sinclair, [The One Who Shall Grip] Sinclair
            can work fine albeit not as strong in burn teams. Although do not
            put [LCB] Yi Sang as support passive as he can go to 45 sanity
            easily,
          </li>
          <li>
            <strong>[The One Who Grips] Faust</strong> - All burn IDs with the
            exception of two has mostly blunt and pierce this makes gaze really
            useful as almost everyone benefits from it, also [Dawn Office Fixer]
            Sinclair or [The One Who Shall Grip] Sinclair can benefit from the
            sp heal to prevent corrosion or lengthen the duration of [Dawn
            Office Fixer] Sinclair E.G.O state.
          </li>
        </ul>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default LimbusRerollPage;

export const Head: React.FC = () => (
  <Seo
    title="Beginner team ideas | Limbus Company | Prydwen Institute"
    description="Struggling with building a team in Limbus Company? Get some inspiration inside!"
    game="limbus"
  />
);
